<template>
  <div>
    <div class="goodsdetail">
      <particulars ref="detail"></particulars>

      <div
        class="orderNumber"
        v-if="
          gruopData.biddingStatus != 2 &&
          gruopData.biddingStatus != 3 &&
          gruopData.biddingStatus != 4 &&
          (gruopData?.lineType != 1 || ifShortEnterprise)
        "
      >
        <div
          class="order"
          v-if="
            enterpriseStatus != 7 &&
            gruopData?.lineType != 1 &&
            enterpriseStatus != 6
          "
        >
          <el-button
            type="primary"
            class="join"
            v-if="enterpriseStatus == 2"
            @click="primaryClick"
            >参与竞拍</el-button
          >
          <div v-if="enterpriseStatus == 4">
            <el-button type="primary" class="join" @click="unloaderClick"
              >参与竞拍</el-button
            >
          </div>

          <el-button
            type="primary"
            class="join"
            @click="auctionClick"
            v-if="enterpriseStatus != 2 && enterpriseStatus != 4"
            >参与竞拍</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follow"
            v-if="gruopData.followFlag == 0"
            >收藏商品</el-button
          >
          <el-button
            class="collect"
            icon="el-icon-plus"
            @click="follows"
            v-if="gruopData.followFlag == 1"
            >取消收藏</el-button
          >

          <div
            style="margin-left: 20px"
            v-if="
              gruopData.distanceStartTime && gruopData.distanceStartTime != '0'
            "
          >
            <div style="background-color: #f7f7f7">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 9px;
                  font-size: 16px;
                  color: #333;
                "
              >
                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
                <div style="margin: 0 5px">距离开始还有</div>

                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
              </div>
              <CountDown
                v-if="gruopData.distanceStartTime"
                :endDate="`${gruopData.distanceStartTime}`"
              ></CountDown>
            </div>
          </div>

          <div style="margin-left: 20px" v-if="gruopData.biddingStatus == 1">
            <div style="background-color: #f7f7f7">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 9px;
                  font-size: 16px;
                  color: #333;
                "
              >
                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
                <div style="margin: 0 5px">距离结束还有</div>

                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
              </div>
              <CountDown
                v-if="gruopData.residueTime"
                :endDate="`${gruopData.residueTime}`"
              ></CountDown>
            </div>
          </div>
        </div>

        <div
          class="form-box"
          v-if="
            ((enterpriseStatus == 6 || enterpriseStatus == 7) &&
              gruopData?.lineType != 1) ||
            ifShortEnterprise
          "
        >
          <div class="form">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="form"
              label-width="90px"
            >
              <el-form-item
                label="报价金额"
                label-width="100px"
                prop="offerPrice"
                class="offerPrice"
              >
                <i class="el-icon-minus" @click="sub"></i>
                <el-input
                  v-model="ruleForm.offerPrice"
                  maxlength="2100000000"
                  @input="formatData('offerPrice')"
                  @blur="formatInput1('offerPrice')"
                ></el-input>
                <i class="el-icon-plus" @click="add"></i>
                <span>元/吨</span>
                <el-button
                  type="primary"
                  @click="quotationClick"
                  v-if="enterpriseStatus == 7"
                  style="margin-left: 10px"
                  >立即报价</el-button
                >
                <el-button
                  type="primary"
                  @click="quotationClick"
                  v-if="enterpriseStatus == 6"
                  style="margin-left: 10px"
                  >支付保证金</el-button
                >
                <el-button
                  class="collect"
                  icon="el-icon-plus"
                  @click="follow"
                  v-if="gruopData.followFlag == 0"
                  >收藏商品</el-button
                >
                <el-button
                  class="collect"
                  icon="el-icon-plus"
                  @click="follows"
                  v-if="gruopData.followFlag == 1"
                  >取消收藏</el-button
                >
              </el-form-item>
            </el-form>

            <div
              v-if="
                gruopData.distanceStartTime &&
                gruopData.distanceStartTime != '0'
              "
            >
              <div style="background-color: #f7f7f7">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 9px;
                    font-size: 16px;
                    color: #333;
                  "
                >
                  <div
                    style="
                      width: 53px;
                      height: 1px;
                      background: #2a8de9;
                      display: inline-block;
                    "
                  ></div>
                  <div style="margin: 0 5px">距离开始还有</div>

                  <div
                    style="
                      width: 53px;
                      height: 1px;
                      background: #2a8de9;
                      display: inline-block;
                    "
                  ></div>
                </div>
                <CountDown
                  v-if="gruopData.distanceStartTime"
                  :endDate="`${gruopData.distanceStartTime}`"
                ></CountDown>
              </div>
            </div>

            <div v-if="gruopData.biddingStatus == 1">
              <div style="background-color: #f7f7f7">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 9px;
                    font-size: 16px;
                    color: #333;
                  "
                >
                  <div
                    style="
                      width: 53px;
                      height: 1px;
                      background: #2a8de9;
                      display: inline-block;
                    "
                  ></div>
                  <div style="margin: 0 5px">距离结束还有</div>

                  <div
                    style="
                      width: 53px;
                      height: 1px;
                      background: #2a8de9;
                      display: inline-block;
                    "
                  ></div>
                </div>
                <CountDown
                  v-if="gruopData.residueTime"
                  :endDate="`${gruopData.residueTime}`"
                ></CountDown>
              </div>
            </div>
          </div>
          <div class="lowestDiffPrice">
            最低降价{{ data.lowestDiffPrice }}元
          </div>
        </div>
      </div>
      <div v-if="gruopData?.lineType == 1 && !ifShortEnterprise">
        <div
          v-if="
            gruopData.distanceStartTime && gruopData.distanceStartTime != '0'
          "
        >
          <div
            style="
              background-color: #f7f7f7;
              margin-top: 10px;
              padding: 10px 10px 20px 10px;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 9px;
                font-size: 16px;
                color: #333;
              "
            >
              <div
                style="
                  width: 53px;
                  height: 1px;
                  background: #2a8de9;
                  display: inline-block;
                "
              ></div>
              <div style="margin: 0 5px">距离开始还有</div>

              <div
                style="
                  width: 53px;
                  height: 1px;
                  background: #2a8de9;
                  display: inline-block;
                "
              ></div>
            </div>
            <CountDown
              v-if="gruopData.distanceStartTime"
              :endDate="`${gruopData.distanceStartTime}`"
            ></CountDown>
          </div>
        </div>

        <div v-if="gruopData.biddingStatus == 1">
          <div
            style="
              background-color: #f7f7f7;
              margin-top: 10px;
              padding: 10px 10px 20px 10px;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                margin-bottom: 9px;
                font-size: 16px;
                color: #333;
              "
            >
              <div
                style="
                  width: 53px;
                  height: 1px;
                  background: #2a8de9;
                  display: inline-block;
                "
              ></div>
              <div style="margin: 0 5px">距离结束还有</div>

              <div
                style="
                  width: 53px;
                  height: 1px;
                  background: #2a8de9;
                  display: inline-block;
                "
              ></div>
            </div>
            <CountDown
              v-if="gruopData.residueTime"
              :endDate="`${gruopData.residueTime}`"
            ></CountDown>
          </div>
        </div>
      </div>
      <div
        class="img_all"
        v-if="
          gruopData.biddingStatus != 2 &&
          gruopData.biddingStatus != 3 &&
          gruopData.biddingStatus != 4 &&
          gruopData?.lineType != 1
        "
      >
        <div>
          竞价资料:{{ gruopData.infoAuditStatus | infoAuditStatus }}
          {{
            gruopData.infoAuditStatus == 2 && gruopData.biddingStatus == 0
              ? "请等待竞价开始即可参与报价"
              : ""
          }}
        </div>
        <div v-if="gruopData.auditRefuseReason">
          拒绝理由：{{ gruopData.auditRefuseReason }}
        </div>
        <div v-for="(item, index) in fileInfos" :key="index" class="flex_item">
          <div v-for="(items, i) in item.fileInfos" :key="i">
            <div class="origin_item" @click="ItemClick(items)">
              {{ items.originalName }}
            </div>
          </div>
        </div>
      </div>
      <div class="company" v-if="computedLoginAndAuthentication">
        <div
          class="company_list"
          style="display: flex; justify-content: space-between"
        >
          <div>竞价公司</div>
          <div style="cursor: pointer" @click="flushClick">
            <i class="el-icon-refresh"></i>
          </div>
        </div>
        <div class="company_table">
          <el-table :data="tableData" border>
            <el-table-column prop="companyName" align="center" label="竞价公司">
            </el-table-column>
            <el-table-column prop="offerPrice" align="center" label="报价金额">
              <template slot-scope="scope">
                {{
                  scope.row.offerDetailList[0].offerPrice == 0
                    ? "*"
                    : scope.row.offerDetailList[0].offerPrice
                }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="offerCount"
              align="center"
              label="报价数量(吨)"
            >
            </el-table-column> -->
            <el-table-column prop="createTime" align="center" label="报价时间">
            </el-table-column>
            <el-table-column prop="winBidding" align="center" label="中标状态">
              <template slot-scope="scope">
                <!-- {{ scope.row.winBidding | winBidding }} -->
                <div v-if="scope.row.winBidding == 1">
                  <img src="../../../assets/zxx_img/zhongbiao.png" alt="" />
                </div>
                <div v-if="scope.row.winBidding == 0">
                  <div>未中标</div>
                </div>
                <div v-if="scope.row.winBidding == '-1'">
                  <div>竞价中</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="details_bottom">
        <regulation class="regulation_bottom" :data="data"></regulation>
      </div>
    </div>
    <InputPasswordDialog
      :isShow="passWordIsShows"
      ref="InputPasswordDialog"
      title="请输入交易密码"
      @close="closeDialog('passWordIsShow')"
      @submit="submit"
    />
    <el-dialog title="缴纳保证金" width="30%" :visible.sync="bonddialogo">
      <div>
        <div>请先支付保证金后,进行报价！</div>
        <div>保证金金额：{{ bond }}万元</div>
      </div>
      <el-form :model="offlineForm" ref="offlineForm" :rules="offlineFormRule">
        <el-form-item label="缴纳方式:" prop="bankType">
          <el-radio
            v-for="(item, index) in balances"
            :key="index"
            v-model="offlineForm.bankType"
            :label="item.bankType"
            :disabled="item.ifAsh"
          >
            <span v-if="item.bankType == 0"
              >中信账户余额(￥{{ item.balance }})</span
            >
            <span v-if="item.bankType == 1"
              >中金账户余额(￥{{ item.balance }})</span
            >
          </el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="bonddialogo = false">取 消</el-button>
        <el-button type="primary" @click="flineFormClicks()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisibleUpload"
      width="30%"
    >
      <div>
        <div style="margin: 10px 0">{{ dialogCont }}</div>
        <el-upload
          class="upload-demo"
          action="#"
          :http-request="onSuccess"
          multiple
          :limit="10"
          :file-list="fileList"
          :accept="type == 2 ? '.jpg, .jpeg, .png' : '.jpg, .jpeg, .png,.PDF'"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            {{
              type == 2
                ? "(可上传JPG/JPEG/PNG格式文件)"
                : "(可上传JPG/JPEG/PNG/PDF格式文件)"
            }}
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleUpload = false">取 消</el-button>
        <el-button type="primary" @click="UploadClick">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="imgUrldialogVisible" width="30%">
      <div class="iamg_y">
        <!-- <img :src="imgUrl" alt="" /> -->
        <el-button
          @click="examinecontract(imgUrl)"
          v-if="
            (imgUrl && imgUrl.slice(-4) == '.pdf') ||
            (imgUrl && imgUrl.slice(-4) == '.PDF')
          "
        >
          点击查看PDF文件<br /><br /><br />{{ originalName }}</el-button
        >
        <el-image
          v-else
          style="width: 100px; height: 100px"
          :src="imgUrl"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imgUrldialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="imgUrldialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import particulars from "./particulars.vue";
import CountDown from "@/components/countDowns.vue";
import { tGroupDtetails, ossUploadFiletwo } from "@/api/public.js";
import { getContractOSSFile } from "@/api/userCenters.js";
import { amountCalculation } from "@/api/public.js";
import {
  getEnterpriseStatus,
  uploadInformation,
  applyToBeService,
  getTbGoodsDetail,
  tradeOfferpage,
} from "@/api/Logistics.js";
import { formatDate } from "@/utils/date";
import {
  reqIsPayBonds,
  reqFollow,
  joinGroup,
  reqAddBiddingOffer,
  reqPayBond,
} from "@/api/hy";
import * as types from "@/store/action-types";
import md5 from "js-md5";
import Vue from "vue";
import InputPasswordDialog from "./passwordInput.vue";
import { getAccountBalanceByParam } from "@/api/Accountopening";
import regulation from "./regulation.vue";
const { baseUrl } = require("@/config");
import { mapState, mapActions } from "vuex";
export default {
  components: {
    particulars,
    InputPasswordDialog,
    regulation,
    CountDown,
  },
  name: "",
  data() {
    return {
      dialogTitle: "参与竞拍资料上传",
      dialogCont: "请上传相关资料",
      gruopData: {},
      bonddialogo: false,
      ruleForm: {
        num: "",
        offerPrice: "",
      },
      rules: {
        num: [
          {
            required: true,
            message: "请输入可供应数量",
            trigger: "blur",
          },
        ],
        offerPrice: [
          {
            required: true,
            message: "请输入报价金额",
            trigger: "blur",
          },
        ],
      },
      bond: 0,
      srcList: [],
      baseUrl: baseUrl,
      imgUrldialogVisible: false,
      imgUrl: "",
      fileInfos: [],
      type: 1,
      fileList: [],
      dialogVisibleUpload: false,
      enterpriseStatus: 0,
      enterMessge: "",
      data: {},
      offlineForm: {
        bankType: "",
      },
      balances: [],
      passWordIsShows: false,
      dialogofflineForm: false,
      templateSelection: "",
      dialogVisible: false,
      dialogVisible1: false,
      pageData: [],
      num: "",
      TbaleList: [],
      gruopData: [],
      tableData: [],
      allPice: 0,
      offlineFormRule: {
        bankType: [
          { required: true, message: "请选择支付方式", trigger: "change" },
        ],
      },
      fileL: [],
      storageName: "",
      originalName: "",
      // userId:''
    };
  },
  mounted() {
    this.getlisst();
    this.getNewlist();
    this.getstatus();
    this.getTbGoodsDetail();
    // this.userId=Vue.ls.get("userId")
  },
  computed: {
    ...mapState({
      massage: (state) => state.user.massage,
    }),
    ifShortEnterprise() {
      return this.gruopData?.lineType == 1 && this.gruopData?.ifShortEnterprise;
    },
    computedLoginAndAuthentication() {
      let status =
        this.massage == "未登录请前往登录" ||
        this.massage == "请前往认证后操作";
      return !status;
    },
  },
  filters: {
    formatCreateTime(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    winBidding(val) {
      let _data = {
        "-1": "竞价中",
        0: "未中标",
        1: "已中标",
      };
      return _data[val];
    },
    infoAuditStatus(val) {
      let _data = {
        0: "待审核",
        1: "管理后台审核已通过,请等待发布方审核",
        2: "审核通过",
        3: "审核拒绝",
      };
      return _data[val];
    },
  },
  methods: {
    examinecontract(item) {
      window.open(item);
    },
    quotationClick() {
      if (this.enterpriseStatus == 6) {
        this.btnClick();
        this.bonddialogo = true;
      }
      if (this.enterpriseStatus == 7) {
        if (this.ruleForm.offerPrice == "") {
          this.$message.error("请输入报价金额");
          return;
        }
        let config = {
          goodsId: this.$route.query.goodsId,
          offerDetailList: [
            {
              categoryId: this.data.categoryList[0].categoryId,
              offerCount: 0,
              offerPrice: this.ruleForm.offerPrice,
            },
          ],
        };
        reqAddBiddingOffer(config).then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.$message({
              message: "报价成功",
              type: "success",
            });
            this.getNewlist();
          } else {
            this.$message({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    },

    sub() {
      let config = {
        dataList: [
          {
            num1: !isNaN(this.ruleForm.offerPrice)
              ? Number(this.ruleForm.offerPrice)
              : 0,
            num2: 1,
          },
          {
            num1: Number(this.data.lowestDiffPrice) * -1,
            num2: 1,
          },
        ],
      };
      amountCalculation(config).then((res) => {
        this.ruleForm.offerPrice = Number(res.data) < 0 ? 0 : res.data;
      });
      // if (Number(this.ruleForm.offerPrice) == 0) {
      //   this.ruleForm.offerPrice = 0;
      // } else {
      //   this.ruleForm.offerPrice =
      //     Number(this.ruleForm.offerPrice) * 100 -
      //       Number(this.data.lowestDiffPrice) * 100 <
      //     0
      //       ? 0
      //       : (Number(this.ruleForm.offerPrice) * 100 -
      //           Number(this.data.lowestDiffPrice) * 100) /
      //         100;
      // }
    },
    add() {
      let config = {
        dataList: [
          {
            num1: !isNaN(this.ruleForm.offerPrice)
              ? Number(this.ruleForm.offerPrice)
              : 0,
            num2: 1,
          },
          {
            num1: Number(this.data.lowestDiffPrice),
            num2: 1,
          },
        ],
      };
      amountCalculation(config).then((res) => {
        this.ruleForm.offerPrice =
          Number(res.data) > Number(this.data.floorPrice)
            ? this.data.floorPrice
            : res.data;
      });
      // this.ruleForm.offerPrice =
      //   (Number(this.ruleForm.offerPrice) * 100 +
      //     Number(this.data.lowestDiffPrice) * 100) /
      //   100;
    },
    ItemClick(e) {
      console.log(e);
      this.imgUrldialogVisible = true;
      this.originalName = e.originalName;
      this.imgUrl = this.baseUrl + "third/third/ossGetFile/" + e.storageName;
      this.srcList.push(
        this.baseUrl + "third/third/ossGetFile/" + e.storageName
      );
    },
    getTbGoodsDetail() {
      getTbGoodsDetail(this.$route.query.goodsId).then((res) => {
        if (res.code == 0) {
          this.bond = res.data.bond;
          this.fileInfos = res.data.informationList;
          this.gruopData = res.data;
          this.data = res.data;
          this.ruleForm.offerPrice = this.data.floorPrice;
          console.log(this.data, "ldldldldldldl");
        }
      });
    },
    primaryClick() {
      let id = Vue.ls.get("userId");
      if (!id) {
        this.$message.error("请登录账号");
        return false;
      }
      this.dialogVisibleUpload = true;
      this.dialogTitle = "提示";
      this.dialogCont =
        "您当前企业类型非物流服务商,需上传道路运输许可证后,方可参与物流竞价!";
      this.type = 2;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    UploadClick() {
      if (this.type == 1) {
        let config = {
          fileIds: this.fileL,
          goodsId: this.$route.query.goodsId,
        };
        uploadInformation(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.getstatus();
            this.getTbGoodsDetail();
            this.dialogVisibleUpload = false;
          }
        });
      } else if (this.type == 2) {
        let config = {
          roadTransportLicenseUrl: this.storageName,
        };
        applyToBeService(config).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.dialogVisibleUpload = false;
            this.getstatus();
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      }
    },
    onSuccess(file) {
      // console.log(file,this.fileList)
      let otherFiles = file.file;
      var formData = new FormData();
      formData.append("file", otherFiles);
      ossUploadFiletwo(formData).then((res) => {
        if (res.code == 0) {
          let list = [];
          this.storageName = res.data.storageName;
          list.push(res.data.fileId);
          var newArr = [...new Set(list)];
          this.fileL = newArr;
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.getstatus();
        }
      });
    },
    unloaderClick() {
      let id = Vue.ls.get("userId");
      if (!id) {
        this.$message.error("请登录账号");
        return false;
      }
      this.dialogVisibleUpload = true;
      this.type = 1;
    },
    // 参与竞拍（多重判断）
    auctionClick() {
      let id = Vue.ls.get("userId");
      if (!id) {
        this.$message.error("请登录账号");
        return false;
      }
      if (
        this.enterpriseStatus == 0 ||
        this.enterpriseStatus == 1 ||
        this.enterpriseStatus == 3 ||
        this.enterpriseStatus == 5
      ) {
        this.$message({
          message: this.enterMessge,
          type: "warning",
        });
      }
    },
    getstatus() {
      let id = this.$route.query.goodsId;
      getEnterpriseStatus(id).then((res) => {
        if (res.code == 0) {
          this.enterpriseStatus = res.data.enterpriseStatus;
          this.enterMessge = res.data.message;
          console.log(res.data, "ssssssssssssssssss");
        }
      });
    },
    flushClick() {
      this.getNewlist();
    },
    //签合同
    goContract() {
      this.$router.push({
        path: "/order",
      });
    },
    btnClick() {
      let config = {
        searchType: 0,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          this.balances = res.data;
          this.dialogofflineForm = true;
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    flineFormClicks(e) {
      this.dialogofflineForm = false;
      let that = this;
      if (that.offlineForm.bankType == 0 || that.offlineForm.bankType == 1) {
        that.passWordIsShows = true;
      }
    },
    getreqIsPayBond() {
      this.btnClick();
    },
    submit(val) {
      console.log(val, "222222");
      let config = {
        bankType: this.offlineForm.bankType,
        goodsId: this.$route.query.goodsId,
        password: md5(val),
      };
      reqPayBond(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "保证金已支付成功，请在竞价开始后进行报价。",
            type: "success",
          });
          this.bonddialogo = false;
          this.getstatus();
          this.getNewlist();
          setTimeout(() => {
            location.reload();
          }, 2000);
          // let configs = {
          //   goodsId: this.$route.query.goodsId,
          //   offerDetailList: [
          //     {
          //       categoryId: this.data.categoryList[0].categoryId,
          //       offerCount: 0,
          //       offerPrice: this.ruleForm.offerPrice,
          //     },
          //   ],
          // };
          // reqAddBiddingOffer(configs).then((res) => {
          //   console.log(res);
          //   if (res.code == 0) {
          //     this.$message({
          //       message: "保证金已支付成功，请在竞价开始后进行报价。",
          //       type: "success",
          //     });
          //     location.reload();
          //     this.bonddialogo = false;
          //     this.getstatus();
          //     this.getNewlist();
          //     this.$route.go(-1);
          //   } else {
          //     this.$message({
          //       message: res.message,
          //       type: "error",
          //     });
          //   }
          // });
          // this.bonddialogo = false;
          // this.getstatus();
          // this.$message({
          //   message: res.data,
          //   type: "success",
          // });
          // this.$route.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    closeDialog(tag) {
      this[tag] = false;
      this.passWordIsShows = false;
    },
    biddingOffer() {},
    openPayBond() {
      this.dialogVisible1 = false;
      let id = Vue.ls.get(types.enterpriseId);
      reqIsPayBonds(id).then((ret) => {
        console.log(ret);
        if (ret.code == 0) {
          console.log(ret.data);
          if (ret.data) {
            this.getreqIsPayBond();
          } else {
            this.$confirm("暂无设置交易密码, 是前往设置?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/finance");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        } else {
          this.$message({
            type: "error",
            message: ret.message,
          });
        }
      });
    },
    toPay() {
      this.dialogVisible = false;
      this.dialogVisible1 = true;
    },

    formatInput1(key) {
      if (this.ruleForm[key].indexOf(".") == this.ruleForm[key].length - 1) {
        this.ruleForm[key] = this.ruleForm[key].slice(
          0,
          this.ruleForm[key].length - 1
        );
      }
    },
    formatData(key) {
      // this.ruleForm[name] = this.ruleForm[name].match(/^\d*(\.?\d?)/g)[0] || ''
      // this.ruleForm[name] = this.ruleForm[name].match(/^\d*/g)[0] || "";
      if (key == "offerPrice") {
        this.ruleForm[key] =
          this.ruleForm[key].match(/\d+\.?\d{0,1}/, "") === null
            ? ""
            : this.ruleForm[key].match(/\d+\.?\d{0,1}/, "")[0];
      } else {
        this.ruleForm[key] =
          this.ruleForm[key].match(/\d+\.?\d{0,2}/, "") === null
            ? ""
            : this.ruleForm[key].match(/\d+\.?\d{0,2}/, "")[0];
      }
    },
    radioChange(e) {
      let newNum =
        Number(this.num) *
        Number(this.gruopData.listingPrice) *
        (Number(this.templateSelection.payRatio) / 100);
      this.allPice = newNum;
    },
    getlisst() {
      tGroupDtetails(this.$route.query.goodsId).then((res) => {
        console.log(res, "sdddddddddddddddddddddddddddddddd");
        if (res.code == 0) {
          console.log(res, "====================");
          // this.tableData = res.data.groupPremiumList;
          this.pageData = res.data.groupPriceList;
          this.TbaleList = res.data.groupEnterpriseList;
          this.gruopData = res.data;
        }
      });
    },
    getNewlist() {
      let config = {
        goodsId: this.$route.query.goodsId,
        page: 1,
        size: 9999,
      };
      tradeOfferpage(config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.records;
          if (this.tableData.length) {
            this.tableData.forEach((item) => {
              item.createTime = item.createTime.replace(/T/g, " ");
            });
          }
        }
      });
    },
    //收藏商品
    follow() {
      if (!this.beforhandle("personalCenter:auctionSale:partake:collect"))
        return;
      reqFollow({
        goodsId: this.$route.query.goodsId,
        type: 0,
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            // this.$refs.detail.getlisst(this.$route.query.goodsId);
            this.getlisst();
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //取消收藏
    follows() {
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reqFollow({
            goodsId: this.$route.query.goodsId,
            type: 1,
          }).then((res) => {
            if (res.code == 0) {
              // this.$refs.detail.getlisst(this.$route.query.goodsId);
              this.getlisst();
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.goodsdetail {
  width: 1200px;
  padding: 25px 20px;
  background: white;
  border: 1px solid #e5e5e5;
  margin: 15px auto;

  &::before,
  &::after {
    display: table;
    content: "";
  }
  .groupInformation {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 25px 20px;
  }

  .titleInfo {
    font-size: 18px;
    font-weight: bold;
  }
  .tableMain {
    display: flex;
    flex-direction: row;
    .pirceTable {
      margin-top: 20px;
      width: 500px;

      .gear {
        display: flex;
        .pirce {
          font-size: 14px;
        }
      }
    }
    .border {
      width: 2px;
      background-color: #6666;
    }
    .tableRight {
      margin-left: 30px;
      margin-top: 20px;
      display: flex;
    }
  }

  .orderNumber {
    margin-top: 10px;
    border: 1px solid #ccc;
    .order {
      display: flex;
      padding: 10px 20px 10px 0;
      .join {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
      .collect {
        margin-left: 10px;
        width: 150px;
        height: 50px;
        margin-top: -3px;
      }
    }
  }
  .success {
    margin-top: 10px;
    border: 1px solid #ccc;
    padding: 20px;
  }
}
.company {
  margin: 20px 0;
  .company_list {
    border: 1px solid #ccc;
    padding: 0 20px;
    font-size: 18px;
    background: #e5e5e5;
    height: 45px;
    line-height: 45px;
    color: #000;
  }
}
.company_table {
  margin-top: 10px;
}
.details_bottom {
  margin-top: 20px;

  .regulation_bottom {
    // margin-left: 20px;
    width: 100%;
  }
}
.img_all {
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}
.flex_item {
  margin-top: 10px;
}
.origin_item {
  color: aqua;
  cursor: pointer;
}
.iamg_y {
  text-align: center;
  img {
    width: 400px;
  }
}
.form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.form-box {
  background: #f7f7f7;
  display: flex;
  // align-items: center;
  padding-top: 16px;
  position: relative;

  .el-input {
    width: 100px;
  }
  .lowestDiffPrice {
    position: absolute;
    left: 100px;
    color: red;
    bottom: 0px;
  }
}

.form-box {
  .btn-wrap {
    .el-button {
      border-radius: 0;
      padding: 0;
      height: 40px;
    }

    .follow {
      padding: 0 5px;
      border-radius: 4px;
      height: 40px;
    }

    .add {
      width: 160px;
      border-radius: 4px;
      height: 40px;
      margin-left: 30px;
      margin-bottom: 16px;
    }
  }
}
.offerPrice {
  &::v-deep .el-input__inner {
    height: 36px !important;
    border-radius: 0;
  }

  i {
    display: inline-flex;
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    cursor: pointer;
  }

  span {
    display: inline-block;
    margin-left: 5px;
  }
}
.countdown {
  margin-right: 10px;
  text-align: center;
}
</style>
