<template>
  <div>
    <div class="goods-detail">
      <div class="top">
        <Particulars @child-event="bindDeatil" ref="detail"></Particulars>
        <div
          class="form-box"
          v-if="
            (detailData.tradeBidding &&
              detailData.tradeBidding.biddingStatus == 1) ||
            (detailData.tradeBidding &&
              detailData.tradeBidding.biddingStatus == 0) ||
            (detailData.tradeGoodsListing &&
              detailData.tradeGoodsListing.listingStatus == 0)
          "
        >
          <div class="form">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="form"
              label-width="90px"
            >
              <el-form-item
                label="下单数量"
                label-width="100px"
                prop="num"
                v-if="selectTradeType == 1 && detailData.listType == 0"
                class="offerPrice"
              >
                <i class="el-icon-minus" @click="subNum"></i>
                <el-input
                  :disabled="isinitialCount"
                  v-model="ruleForm.num"
                  maxlength="2100000000"
                  @input="formatData('num')"
                ></el-input>
                <i class="el-icon-plus" @click="addNum"></i>
                <span>吨</span>
              </el-form-item>
              <div
                v-for="(item, index) in ruleForm.offerDetailList"
                :key="index"
              >
                <el-form-item
                  :label="index == 0 ? '报价金额' : ''"
                  :prop="'offerDetailList.' + index + '.offerPrice'"
                  :rules="{
                    required: true,
                    message: '请输入报价金额',
                    trigger: 'blur',
                  }"
                  label-width="150px"
                  v-if="selectTradeType == 2 && detailData.listType == 0"
                  class="offerPrice"
                >
                  <div class="offerPriceItem">
                    <!-- <span class="goodsName">{{ item.goodsName }}</span> -->
                    <i class="el-icon-minus" @click="sub(index)"></i>
                    <el-input
                      v-model="ruleForm.offerDetailList[index].offerPrice"
                    ></el-input>
                    <i class="el-icon-plus" @click="add(index)"></i>
                    <span>元/吨</span>
                  </div>
                </el-form-item>
              </div>
              <div
                v-if="
                  selectTradeType == 2 &&
                  selectOrderType == 1 &&
                  detailData.listType == 0
                "
                style="margin-left: 160px; color: red"
              >
                <!-- 多商品竞价需要将竞价内所含商品均报价才算报价成功&nbsp;&nbsp;&nbsp;&nbsp; -->
                最低加价{{ detailData.tradeBidding.lowestDiffPrice }}元
              </div>
              <div
                v-if="
                  selectTradeType == 2 &&
                  selectOrderType == 2 &&
                  detailData.listType == 0
                "
                style="margin-left: 160px; color: red"
              >
                <!-- 多商品竞价需要将竞价内所含商品均报价才算报价成功&nbsp;&nbsp;&nbsp;&nbsp; -->
                最低降价{{ detailData.tradeBidding.lowestDiffPrice }}元
              </div>
            </el-form>
          </div>
          <div class="btn-wrap">
            <el-button
              class="add hy-btn"
              @click="createOrder(2)"
              v-if="
                selectTradeType == 1 &&
                selectOrderType == 1 &&
                detailData.listType == 0
              "
              >确认采购</el-button
            >

            <el-button
              class="add hy-btn"
              @click="createOrder(1)"
              v-if="
                selectTradeType == 1 &&
                selectOrderType == 2 &&
                detailData.listType == 0
              "
              >确认供货</el-button
            >
            <el-button
              class="add hy-btn"
              @click="openPayBond(2)"
              v-if="
                selectTradeType == 2 && detailData.listType == 0 && payBond == 1
              "
              >立即报价</el-button
            >
            <el-button
              class="add hy-btn"
              @click="openPayBond(1)"
              v-if="
                selectTradeType == 2 &&
                detailData.listType == 0 &&
                payBond == 0 &&
                (detailData.tradeBidding.partNeedAudit == 0 ||
                  (detailData.tradeBidding.partNeedAudit == 1 &&
                    partAuditStatus == 1))
              "
              >支付保证金</el-button
            >
            <el-button
              class="add hy-btn"
              @click="participateBidding()"
              :disabled="partAuditStatus == 0"
              v-if="
                selectTradeType == 2 &&
                detailData.listType == 0 &&
                payBond == 0 &&
                detailData.tradeBidding.partNeedAudit == 1 &&
                partAuditStatus != 1
              "
              >{{ formatApplyVal }}</el-button
            >
            <el-button
              class="follow"
              icon="el-icon-plus"
              @click="follow"
              v-if="detailData.listType == 0 && detailData.followFlag == 0"
              >收藏商品</el-button
            >
            <el-button
              class="follow"
              icon="el-icon-plus"
              @click="follows"
              v-if="detailData.listType == 0 && detailData.followFlag == 1"
              >取消收藏</el-button
            >
          </div>
          <div v-if="detailData.residueTime && detailData.residueTime != '0'">
            <div style="margin-left: 20px">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 9px;
                  font-size: 16px;
                  color: #333;
                "
              >
                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
                <div style="margin: 0 5px">距离结束还有</div>

                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
              </div>
              <CountDown
                v-if="detailData.residueTime"
                :endDate="`${detailData.residueTime}`"
                @send="sendFun"
                :key="isdata"
              ></CountDown>
            </div>
          </div>

          <div
            v-if="
              detailData.distanceStartTime &&
              detailData.distanceStartTime != '0'
            "
          >
            <div style="margin-left: 20px">
              <div
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 9px;
                  font-size: 16px;
                  color: #333;
                "
              >
                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
                <div style="margin: 0 5px">距离开始还有</div>

                <div
                  style="
                    width: 53px;
                    height: 1px;
                    background: #2a8de9;
                    display: inline-block;
                  "
                ></div>
              </div>
              <CountDown
                v-if="detailData.distanceStartTime"
                :endDate="`${detailData.distanceStartTime}`"
              ></CountDown>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectTradeType == 2">
        <div class="list" v-if="computedLoginAndAuthentication">
          <div class="t">
            <div>竞价公司</div>
            <div style="cursor: pointer" @click="flushClick">
              <i class="el-icon-refresh"></i>
            </div>
          </div>
          <el-table
            :data="tableData.list"
            :span-method="objectSpanMethod"
            border
            style="width: 100%"
          >
            <el-table-column align="center" prop="companyName" label="竞价公司">
            </el-table-column>
            <el-table-column
              align="center"
              prop="goodsName"
              label="竞价商品名称"
            >
            </el-table-column>
            <el-table-column align="center" prop="offerPrice" label="报价金额">
            </el-table-column>
            <el-table-column
              align="center"
              prop="offerCount"
              label="报价数量(吨)"
            >
            </el-table-column>
            <el-table-column prop="createTime" align="center" label="报价时间">
            </el-table-column>
            <el-table-column
              align="center"
              prop="winBidding"
              label="中标状态"
              width="180"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.winBidding == 1">
                  <img src="../../assets/zxx_img/zhongbiao.png" alt="" />
                </div>
                <div v-if="scope.row.winBidding == 0">
                  <div>未中标</div>
                </div>
                <div v-if="scope.row.winBidding == -1">
                  <div>竞拍中</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="tableData.total"
            :page-size="search.size"
            :current-page.sync="search.page"
          >
          </el-pagination>
        </div>
        <div
          class="list"
          v-if="
            detailData.ifPublisher && detailData.tradeBidding.partNeedAudit == 1
          "
        >
          <div class="t">
            <div>审核参与竞拍企业</div>
            <div style="cursor: pointer" @click="listPartBidding">
              <i class="el-icon-refresh"></i>
            </div>
          </div>
          <el-table :data="partBiddingData" border style="width: 100%">
            <el-table-column
              align="center"
              prop="enterpriseName"
              label="竞价公司"
            >
            </el-table-column>
            <el-table-column align="center" prop="telephone" label="注册电话">
            </el-table-column>
            <el-table-column
              prop="createTime"
              align="createTime"
              label="提交时间"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="auditStatus"
              label="状态"
              width="180"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.auditStatus == 0">
                  <div>待审核</div>
                </div>
                <div v-if="scope.row.auditStatus == 1">
                  <div>审核通过</div>
                </div>
                <div v-if="scope.row.auditStatus == 2">
                  <div>审核拒绝</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="winBidding"
              label="操作"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="
                    scope.row.auditStatus == 0 &&
                    (detailData.tradeBidding?.biddingStatus == 0 ||
                      detailData.tradeBidding?.biddingStatus == 1)
                  "
                  @click="Approved(scope.row, 1)"
                  type="text"
                  size="small"
                  >审核通过</el-button
                >
                <el-button
                  v-if="
                    scope.row.auditStatus == 0 &&
                    (detailData.tradeBidding?.biddingStatus == 0 ||
                      detailData.tradeBidding?.biddingStatus == 1)
                  "
                  @click="Approved(scope.row, 2)"
                  type="text"
                  size="small"
                  >审核拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="rule">
          <regulation :data="detailData"></regulation>
        </div>
      </div>
      <div class="evaluate">
        <!-- <Evaluate/> -->
      </div>
    </div>
    <!-- <PayBond
      :data="detailData"
      :isShow="paybondShow"
      @close="closeDialog('paybondShow')"
      @paymentSuccess="paymentSuccessFn"
    /> -->

    <PaymentBond
      v-bind="$attrs"
      v-on="$listeners"
      :data="config"
      :isShow="paymentBondShow"
      @close="closeDialog('paymentBondShow')"
      @paymentSuccess="paymentSuccessFn"
    />
  </div>
</template>

<script>
import {
  reqFollow,
  reqCreateOrder,
  reqAddBiddingOffer,
  reqIsPayBond,
  reqTradeOfferList,
  reqIsPayBonds,
  applyPartBidding,
  listPartBidding,
  auditPartBidding,
} from "@/api/hy";
import { tradeDtetails } from "@/api/public.js";
import floatCompute from "@/utils/floatCompute.js";
import { number } from "echarts";
import * as types from "@/store/action-types";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import Vue from "vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      isdata: 0,
      isinitialCount: false,
      selectTradeType: 0,
      ruleForm: {
        num: "",
        offerDetailList: [],
      },
      rules: {
        num: [
          {
            required: true,
            message: "请输入可供应数量",
            trigger: "blur",
          },
        ],
        offerPrice: [
          {
            required: true,
            message: "请输入报价金额",
            trigger: "blur",
          },
        ],
      },
      detailData: {},
      submiting: false,
      paybondShow: false,
      paymentBondShow: this.paymentBondShow,
      config: {},
      search: {
        page: 1,
        size: 10,
      },
      tableData: {
        total: 0,
        list: [],
      },
      payBond: 0,
      selectOrderType: null,
      partAuditStatus: null,
      partBiddingData: [],
    };
  },
  computed: {
    ...mapState({
      massage: (state) => state.user.massage,
    }),
    computedLoginAndAuthentication() {
      let status =
        this.massage == "未登录请前往登录" ||
        this.massage == "请前往认证后操作";
      return !status;
    },
    formatApplyVal() {
      if (this.partAuditStatus == -1) {
        return "参与竞拍";
      }
      if (this.partAuditStatus == 0) {
        return "待审核";
      }
      return "参与竞拍";
    },
  },
  components: {
    regulation: () => import("@/layout/regulation.vue"),
    Particulars: () => import("@/layout/particulars.vue"),
    PayBond: () => import("./components/payBond.vue"),
    PaymentBond: () => import("./components/paymentBond.vue"),
    CountDown: () => import("@/components/countDowns.vue"),
    // Evaluate: () => import("./components/evaluate.vue"),
  },
  mounted() {
    this.selectTradeType = this.$route.query.selectTradeType;
    this.selectOrderType = this.$route.query.selectOrderType;
    this.getList();
  },
  methods: {
    sendFun(e) {
      console.log(e,'qqqqqqqqqqqqq5555555555555')
      if (e == 0) {
        tradeDtetails(this.$route.query.goodsId).then((res) => {
          if (res.code == 0) {
            this.isdata += 1;
            this.detailData.residueTime = res.data.residueTime;
            this.detailData.tradeBidding.biddingStatus=res.data.tradeBidding.biddingStatus
            this.getList();
          }
        });
      }
    },
    Approved(row, type) {
      auditPartBidding({
        auditId: row.auditId,
        auditReason: "",
        auditStatus: type,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "审核成功",
          });
          this.listPartBidding();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    listPartBidding() {
      listPartBidding({
        biddingId: this.detailData.tradeBidding.biddingId,
      }).then((res) => {
        if (res.code == 0) {
          this.partBiddingData = res.data;
          console.log(res.data, "listPartBidding");
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    participateBidding() {
      applyPartBidding({
        biddingId: this.detailData.tradeBidding.biddingId,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "参与成功",
          });
          this.$refs.detail.getlisst(this.$route.query.goodsId);
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    // getPartAuditStatus() {
    //   reqIsPayBond(this.$route.query.goodsId).then((ret) => {
    //     if (ret.code == 0) {
    //       if (ret.data) {
    //         this.partAuditStatus = ret.data.partAuditStatus;
    //       }
    //     } else {
    //       this.$message({
    //         type: "error",
    //         message: ret.message,
    //       });
    //     }
    //   });
    // },
    mergeCol(id, rowIndex) {
      // debugger;
      // 合并单元格
      // id：属性名
      // rowIndex：行索引值
      var idName = this.tableData.list[rowIndex][id]; // 获取当前单元格的值
      if (rowIndex > 0) {
        // 判断是不是第一行
        // eslint-disable-next-line eqeqeq
        if (
          this.tableData.list[rowIndex][id] !=
          this.tableData.list[rowIndex - 1][id]
        ) {
          // 先判断当前单元格的值是不是和上一行的值相等
          var i = rowIndex;
          var num = 0; // 定义一个变量i，用于记录行索引值并进行循环，num用于计数
          while (i < this.tableData.list.length) {
            // 当索引值小于table的数组长度时，循环执行
            if (this.tableData.list[i][id] === idName) {
              // 判断循环的单元格的值是不是和当前行的值相等
              i++; // 如果相等，则索引值加1
              num++; // 合并的num计数加1
            } else {
              i = this.tableData.list.length; // 如果不相等，将索引值设置为table的数组长度，跳出循环
            }
          }
          return {
            rowspan: num, // 最终将合并的行数返回
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0, // 如果相等，则将rowspan设置为0
            colspan: 1,
          };
        }
      } else {
        // 如果是第一行，则直接返回
        let i = rowIndex;
        let num = 0;
        while (i < this.tableData.list.length) {
          // 当索引值小于table的数组长度时，循环执行
          if (this.tableData.list[i][id] === idName) {
            i++;
            num++;
          } else {
            i = this.tableData.list.length;
          }
        }
        return {
          rowspan: num,
          colspan: 1,
        };
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0 || columnIndex == 4 || columnIndex == 5) {
        return this.mergeCol("offerId", rowIndex);
      }
    },
    handleCurrentChange(val) {
      this.search.page = val;
      this.getList();
    },
    formatTableDate(list) {
      if (!list || list.length == 0) {
        return [];
      }
      let newList = [];
      list.forEach((item) => {
        item.offerDetailList.forEach((i) => {
          if (i.offerPrice == 0) {
            i.offerPrice = "*";
          } else {
            i.offerPrice = "￥" + i.offerPrice;
          }
          newList.push({ ...item, ...i });
        });
      });
      return newList;
    },
    flushClick() {
      reqTradeOfferList({
        ...this.search,
        goodsId: this.$route.query.goodsId,
      })
        .then((ret) => {
          if (ret.code == 0) {
            let list = ret.data.records;
            list.forEach((item) => {
              item.createTime = item.createTime.replace("T", " ");
              if (item.offerPrice == 0) {
                item.offerPrice = "*";
              }
            });

            this.tableData.list = this.formatTableDate(list);
            this.tableData.total = ret.code == 0 ? ret.data.total : 0;
            this.$message({
              message: "刷新成功",
              type: "success",
            });
          } else {
            this.tableData.list = [];
            this.tableData.total = 0;
          }
        })
        .catch((err) => {
          console.log(err);
          this.tableData.list = [];
          this.tableData.total = 0;
        });
    },
    getList() {
      reqTradeOfferList({
        ...this.search,
        goodsId: this.$route.query.goodsId,
      })
        .then((ret) => {
          if (ret.code == 0) {
            console.log(ret.data);
            let list = ret.data.records;
            list.forEach((item) => {
              item.createTime = item.createTime.replace("T", " ");
              if (item.offerPrice == 0) {
                item.offerPrice = "*";
              }
            });
            this.tableData.list = this.formatTableDate(list);
            console.log(this.tableData.list);
            this.tableData.total = ret.code == 0 ? ret.data.total : 0;
          } else {
            this.tableData.list = [];
            this.tableData.total = 0;
          }
        })
        .catch((err) => {
          console.log(err);
          this.tableData.list = [];
          this.tableData.total = 0;
        });
      reqIsPayBond(this.$route.query.goodsId).then((ret) => {
        // console.log(ret);
        if (ret.code == 0) {
          this.payBond = ret.data.payBond;
        }
      });
    },
    paymentSuccessFn() {
      let id = this.$route.query.goodsId;
      this.$refs.detail.getlisst(id);
      reqIsPayBond(this.$route.query.goodsId).then((ret) => {
        // console.log(ret);
        if (ret.code == 0) {
          this.payBond = ret.data.payBond;
        }
      });
    },
    bindDeatil(data) {
      this.detailData = data;
      this.partAuditStatus = this.detailData.tradeBidding.partAuditStatus;
      if (this.$route.query.selectTradeType == 2) {
        this.ruleForm.offerDetailList = data.goodsCategoryList.map((item) => {
          return {
            categoryId: item.categoryId,
            offerPrice: item.goodsPrice,
            goodsPrice: item.goodsPrice,
            goodsName: item.goodsName,
          };
        });
        console.log(this.ruleForm);
      }

      if (
        this.detailData.tradeGoodsListing &&
        this.detailData.tradeGoodsListing.ifSplit == 1
      ) {
        if (this.detailData.tradeGoods.initialCount) {
          this.ruleForm.num = this.detailData.tradeGoods.initialCount;
          this.isinitialCount = true;
        }
      }
      this.listPartBidding();
    },
    subNum() {
      if (this.isinitialCount) {
        return false;
      }
      this.ruleForm.num = floatCompute.floatSub(+this.ruleForm.num, 1);
      if (this.ruleForm.num <= 0) {
        this.ruleForm.num = 0;
      }
    },
    addNum() {
      if (this.isinitialCount) {
        return false;
      }
      this.ruleForm.num = floatCompute.floatAdd(+this.ruleForm.num, 1);
    },
    sub(index) {
      let data = this.detailData.tradeBidding;
      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 1
      ) {
        if (this.ruleForm.offerDetailList[index].offerPrice <= 0) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice ==
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else {
          this.ruleForm.offerDetailList[index].offerPrice =
            Number(this.ruleForm.offerDetailList[index].offerPrice) -
            Number(data.lowestDiffPrice);
        }
      } else {
        if (
          this.ruleForm.offerDetailList[index].offerPrice -
            data.lowestDiffPrice <=
          0
        ) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (this.ruleForm.offerDetailList[index].offerPrice <= 0) {
          this.ruleForm.offerDetailList[index].offerPrice = 0;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice <=
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            Number(this.ruleForm.offerDetailList[index].offerPrice) -
            Number(data.lowestDiffPrice);
        }
      }
    },
    add(index) {
      let data = this.detailData.tradeBidding;

      if (
        this.$route.query.selectTradeType == 2 &&
        this.$route.query.selectOrderType == 1
      ) {
        this.ruleForm.offerDetailList[index].offerPrice =
          Number(this.ruleForm.offerDetailList[index].offerPrice) +
          Number(data.lowestDiffPrice);
      } else {
        if (
          this.ruleForm.offerDetailList[index].offerPrice +
            data.lowestDiffPrice >=
          this.ruleForm.offerDetailList[index].offerPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else if (
          this.ruleForm.offerDetailList[index].offerPrice >=
          this.ruleForm.offerDetailList[index].goodsPrice
        ) {
          this.ruleForm.offerDetailList[index].offerPrice =
            this.ruleForm.offerDetailList[index].goodsPrice;
        } else {
          this.ruleForm.offerDetailList[index].offerPrice =
            Number(this.ruleForm.offerDetailList[index].offerPrice) +
            Number(data.lowestDiffPrice);
        }
      }
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    createOrder(ro) {
      this.$confirm("请确认是否下单，确认后无法撤销，请谨慎操作", "提示", {
        confirmButtonText: "确认下单",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // if (!this.beforhandle(["listingPurchase:order", "listingSales:order"]))
          // return;
          // selectTradeType == 1 &&
          //         selectOrderType == 1 &&
          //         detailData.listType == 0

          let roe = this.$route.query;
          if (
            roe.selectTradeType == 1 &&
            roe.selectOrderType == 2 &&
            this.detailData.listType == 0 &&
            ro == 1 &&
            roe.title == "挂牌采购"
          ) {
            // personalCenter:goodsCollect:detail:listingPurchase:order
            if (
              !this.beforhandle(
                "personalCenter:goodsCollect:detail:listingPurchase:order"
              )
            )
              return;
          } else {
            if (!this.beforhandle("listingPurchase:order")) return;
          }

          if (
            roe.selectTradeType == 1 &&
            roe.selectOrderType == 1 &&
            this.detailData.listType == 0 &&
            ro == 2 &&
            roe.title == "挂牌销售"
          ) {
            // personalCenter:goodsCollect:detail:listingSales:order
            if (
              !this.beforhandle(
                "personalCenter:goodsCollect:detail:listingSales:order"
              )
            )
              return;
          } else {
            if (!this.beforhandle("listingSales:order")) return;
          }
          this.$refs.form.validate((valid) => {
            if (valid) {
              if (this.submiting) return;
              this.submiting = true;

              reqCreateOrder({
                goodsId: this.$route.query.goodsId,
                num: this.ruleForm.num,
              })
                .then((ret) => {
                  if (ret.code == 0) {
                    // this.ruleForm.num = ''
                    // this.$message({
                    //   type: "success",
                    //   message: "操作成功",
                    // });
                    // this.$refs.detail.getlisst();
                    this.$confirm(
                      "信息已经提交成功，是否前往我的订单内查看？",
                      "提示",
                      {
                        confirmButtonText: "我的订单",
                        cancelButtonText: "取消",
                        type: "warning",
                        customClass: "hy-confirm",
                        iconClass: "hy-icon-dingdanchenggong",
                      }
                    )
                      .then(() => {
                        this.$router.push({
                          path: "/order",
                          query: {
                            title: "订单信息",
                          },
                        });
                      })
                      .catch(() => {
                        console.log(111);
                        // this.$router.back(-1);
                      });
                  } else {
                    this.$message({
                      type: "error",
                      message: ret.message,
                    });
                  }
                  this.submiting = false;
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    getreqIsPayBond() {
      if (this.detailData.config && this.detailData.config.payBond == 1) {
        this.biddingOffer();
        return;
      }
      let that = this;
      reqIsPayBond(that.$route.query.goodsId).then((ret) => {
        that.detailData.config = ret.data;
        // console.log(ret);
        if (ret.code == 0) {
          this.detailData.sets.forEach((item) => {
            if (ret.data.payBond == 0) {
              that.paymentBondShow = true;
              this.config = ret.data;
            } else {
              this.biddingOffer();
            }
          });
        } else {
          this.$message.error(ret.message);
        }
      }),
        (err) => {
          console.log(err);
        };
    },
    earnest() {},
    openPayBond(ro) {
      // if (
      //   !this.beforhandle([
      //     "auctionSale:bond",
      //     "auctionSale:offer",
      //     "biddingPurchase:offer",
      //     "biddingPurchase:bond",
      //   ])
      // )
      //   return;

      let route = this.$route.query;
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 1 &&
        this.detailData.listType == 0 &&
        this.payBond == 0 &&
        ro == 1 &&
        route.title == "竞价销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:auctionSale:bond"
          )
        )
          return;
      } else {
        if (
          route.selectTradeType == 2 &&
          route.selectOrderType == 1 &&
          this.detailData.listType == 0 &&
          this.payBond == 0 &&
          ro == 1
        ) {
          if (!this.beforhandle("auctionSale:bond")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 1 &&
        this.detailData.listType == 0 &&
        this.payBond == 1 &&
        ro == 2 &&
        route.title == "竞价销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:auctionSale:offer"
          )
        )
          return;
      } else {
        if (
          route.selectTradeType == 2 &&
          route.selectOrderType == 1 &&
          this.detailData.listType == 0 &&
          this.payBond == 1 &&
          ro == 2
        ) {
          if (!this.beforhandle("auctionSale:offer")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 2 &&
        this.detailData.listType == 0 &&
        this.payBond == 0 &&
        ro == 1 &&
        route.title == "竞价采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:biddingPurchase:bond"
          )
        )
          return;
      } else {
        if (
          route.selectTradeType == 2 &&
          route.selectOrderType == 2 &&
          this.detailData.listType == 0 &&
          this.payBond == 0 &&
          ro == 1
        ) {
          if (!this.beforhandle("biddingPurchase:bond")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 2 &&
        this.detailData.listType == 0 &&
        this.payBond == 1 &&
        ro == 2 &&
        route.title == "竞价采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:biddingPurchase:offer"
          )
        )
          return;
      } else {
        if (
          route.selectTradeType == 2 &&
          route.selectOrderType == 2 &&
          this.detailData.listType == 0 &&
          this.payBond == 1 &&
          ro == 2
        ) {
          if (!this.beforhandle("biddingPurchase:offer")) return;
        }
      }

      let id = Vue.ls.get(types.enterpriseId);
      reqIsPayBonds(id).then((ret) => {
        // that.detailData.config = ret.data;
        // console.log(ret);
        if (ret.code == 0) {
          // console.log(ret.data);
          if (ret.data) {
            this.getreqIsPayBond();

            // that.paymentBondShow = true;
          } else {
            // this.$message({
            //   type: "error",
            //   message: "请设置交易密码",
            // });
            this.$confirm("暂无设置交易密码, 是前往设置?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/finance");
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          }
        } else {
          // that.biddingOffer();
          this.$message({
            type: "error",
            message: ret.message,
          });
        }
      });
    },

    biddingOffer() {
      let _this = this;
      // console.log("biddingOffer");
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.submiting) return;
          this.submiting = true;
          let config = {
            goodsId: this.$route.query.goodsId,
            offerDetailList: this.ruleForm.offerDetailList.map((item) => {
              return {
                categoryId: item.categoryId,
                offerPrice: item.offerPrice,
              };
            }),
          };
          console.log(config);
          reqAddBiddingOffer(config)
            .then((ret) => {
              if (ret.code == 0) {
                this.$message({
                  type: "success",
                  message: "报价成功",
                });
                this.getList();
                // tradeDtetails(_this.$route.query.goodsId).then(res=>{
                //   if (res.code == 0){
                //     this.isdata+=1
                //     this.detailData.residueTime=res.data.residueTime
                //   }
                // })
              } else {
                this.$message({
                  type: "error",
                  message: ret.message,
                });
              }
              this.submiting = false;
            })
            .catch((err) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formatData(name) {
      // this.ruleForm[name] = this.ruleForm[name].match(/^\d*(\.?\d?)/g)[0] || ''
      this.ruleForm[name] = this.ruleForm[name].match(/^\d*/g)[0] || "";
    },
    follows() {
      let route = this.$route.query;
      if (
        route.selectTradeType == 1 &&
        route.selectOrderType == 2 &&
        route.title == "挂牌采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:listingPurchase:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 1 && route.selectOrderType == 2) {
          if (!this.beforhandle("listingPurchase:collect")) return;
        }
      }
      if (
        route.selectTradeType == 1 &&
        route.selectOrderType == 1 &&
        route.title == "挂牌销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:listingSales:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 1 && route.selectOrderType == 1) {
          if (!this.beforhandle("listingSales:collect")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 1 &&
        route.title == "竞价销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:auctionSale:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 2 && route.selectOrderType == 1) {
          if (!this.beforhandle("auctionSale:collect")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 2 &&
        route.title == "竞价采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:biddingPurchase:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 2 && route.selectOrderType == 2) {
          if (!this.beforhandle("biddingPurchase:collect")) return;
        }
      }
      this.$confirm("是否要取消收藏?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          reqFollow({
            goodsId: this.$route.query.goodsId,
            type: 1,
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "取消收藏成功!",
              });
              let id = this.$route.query.goodsId;
              this.$refs.detail.getlisst(id);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    follow() {
      // if (
      //   !this.beforhandle([
      //     "listingPurchase:collect",
      //     "listingSales:collect",
      //     "auctionSale:collect",
      //     "biddingPurchase:collect",
      //   ])
      // )
      //   return;
      let route = this.$route.query;
      if (
        route.selectTradeType == 1 &&
        route.selectOrderType == 2 &&
        route.title == "挂牌采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:listingPurchase:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 1 && route.selectOrderType == 2) {
          if (!this.beforhandle("listingPurchase:collect")) return;
        }
      }
      if (
        route.selectTradeType == 1 &&
        route.selectOrderType == 1 &&
        route.title == "挂牌销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:listingSales:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 1 && route.selectOrderType == 1) {
          if (!this.beforhandle("listingSales:collect")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 1 &&
        route.title == "竞价销售"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:auctionSale:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 2 && route.selectOrderType == 1) {
          if (!this.beforhandle("auctionSale:collect")) return;
        }
      }
      if (
        route.selectTradeType == 2 &&
        route.selectOrderType == 2 &&
        route.title == "竞价采购"
      ) {
        if (
          !this.beforhandle(
            "personalCenter:goodsCollect:detail:biddingPurchase:collect"
          )
        )
          return;
      } else {
        if (route.selectTradeType == 2 && route.selectOrderType == 2) {
          if (!this.beforhandle("biddingPurchase:collect")) return;
        }
      }
      if (this.submiting) return;
      this.submiting = true;
      reqFollow({
        goodsId: this.$route.query.goodsId,
        type: 0,
      })
        .then((ret) => {
          if (ret.code == 0) {
            this.$confirm("添加收藏成功", "提示", {
              confirmButtonText: "关闭",
              cancelButtonText: "关闭",
              confirmButtonClass: "follow-confirm-btn",
              type: "warning",
              customClass: "hy-confirm follow-confirm",
              iconClass: "hy-icon-guanzhuchenggong",
            })
              .then(() => {
                let id = this.$route.query.goodsId;
                this.$refs.detail.getlisst(id);
              })
              .catch(() => {
                let id = this.$route.query.goodsId;
                this.$refs.detail.getlisst(id);
              });
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
          this.submiting = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}

.goods-detail {
  width: 1200px;
  padding: 25px 20px;
  background: white;
  border: 1px solid #e5e5e5;
  margin: 15px auto;

  &::before,
  &::after {
    display: table;
    content: "";
  }
}

.info {
  flex: 1;
  padding: 20px;
  border: 1px solid #222;
}

.right {
  width: 300px;
  flex-shrink: 0;
  margin-left: 20px;
}

.release-info {
  font-size: 18px;

  .title {
    text-align: center;
    margin-bottom: 30px;
  }

  p {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.form-box {
  margin-top: 20px;
  background: #f7f7f7;
  display: flex;
  // align-items: center;
  padding-top: 16px;

  .el-input {
    width: 100px;
  }
}

.form-box {
  .btn-wrap {
    .el-button {
      border-radius: 0;
      padding: 0;
      height: 40px;
    }

    .follow {
      padding: 0 5px;
      border-radius: 4px;
      height: 40px;
    }

    .add {
      width: 160px;
      border-radius: 4px;
      height: 40px;
      margin-left: 30px;
      margin-bottom: 16px;
    }
  }
}

.evaluate {
  margin-top: 40px;
}

.offerPrice {
  &::v-deep .el-input__inner {
    height: 40px !important;
    border-radius: 0;
  }

  i {
    display: inline-flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    cursor: pointer;
  }
  .offerPriceItem {
    display: flex;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    .goodsName {
      display: inline-flex;
      align-items: center;
      border: 1px solid #e5e5e5;
      min-height: 40px;
      width: 120px;
      line-height: 1;
      padding: 0 10px;
      margin-top: 0;
    }
  }

  span {
    display: inline-block;
    margin-left: 5px;
  }
}

.hy-confirm::v-deep .el-message-box__container {
  width: 330px !important;
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}

.list {
  margin-top: 20px;

  .t {
    border: 1px solid #e5e5e5;
    background: #f7f7f7;
    padding: 14px 22px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }

  .el-table {
    border: 1px solid #ebebeb;
  }
}

.rule {
  margin-top: 20px;
}
</style>
<style>
.hy-icon-dingdanchenggong + .el-message-box__message {
  margin-right: 10px;
}

.hy-icon-dingdanchenggong {
  font-size: 40px !important;
  display: inline-block;
  color: #5a9bf2;
}

.hy-icon-guanzhuchenggong {
  font-size: 30px !important;
  display: inline-block;
  margin-right: 10px;
}
</style>
